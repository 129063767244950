import { useState, useEffect, useMemo } from 'react';

export function useMediaQuery(query: string) {
    const mql = useMemo(() => window.matchMedia(query), [query]);
    const [matches, setMatch] = useState(mql.matches);
    // console.log(query, 'matches:', matches);
    useEffect(() => {
        const handler = (e: any) => setMatch(e.matches);

        mql.addListener(handler);

        return () => {
            mql.removeListener(handler);
        };
    }, [mql]);

    return matches;
}
