import React, { useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory,
    Redirect,
    useLocation,
} from 'react-router-dom';
import cx from 'classnames';
import {
    Loader,
    // List,
    Menu,
    Segment,
    Icon,
    Table,
    Dropdown,
    Form,
    Modal,
    List,
    Button,
    Image,
    Container,
    Message,
    Sidebar,
    Checkbox,
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import dayjs from 'dayjs';
import 'dayjs/locale/bg';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
    Terms,
    Footer,
    PickupRange,
    STATUS,
    OrderDetails,
    usePickupRange,
    Client,
    Market,
} from 'mo-shared';
import { useMediaQuery } from './utils';

dayjs.locale('bg');
dayjs.extend(localizedFormat);
dayjs.extend(calendar);

const calendarLocale = {
    sameDay: '[Днес]', // The same day ( Today at 2:30 AM )
    nextDay: '[Утре]', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
    lastDay: '[Вчера]', // The day before ( Yesterday at 2:30 AM )
    lastWeek: 'DD.MM.YYYY', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'DD.MM.YYYY', // Everything else ( 7/10/2011 )
};

if (process.env.REACT_APP_FIREBASE_CONFIG) {
    firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
} else {
    console.log('Error initializing firebase - config env variable not found!');
}
if (process.env.NODE_ENV === 'production') {
    firebase.analytics();
}
if (process.env.REACT_APP_SIMULATE_CLOUD) {
    console.log('Set using simulator for functions - http://localhost:5001');
    firebase.functions().useFunctionsEmulator('http://localhost:5001');
}
const ui = new firebaseui.auth.AuthUI(firebase.auth());
const db = firebase.firestore();
const functions = firebase.app().functions('europe-west3');

type ClientsMap = { [uid: string]: Client };

const DataContext = React.createContext<{
    marketInfo: Market | null;
    clients: ClientsMap;
} | null>(null);

const useAuth = () => {
    console.log('useAuth');
    const [user, setUser] = useState<firebase.User | null | undefined>();
    useEffect(() => {
        return firebase.auth().onIdTokenChanged((user) => {
            console.log('Loaded user', user);
            setUser(user);
        });
    }, []);
    return user;
};

const statusDropdownOptions = Object.keys(STATUS).map((k) => ({
    key: k,
    value: k,
    text: k,
}));

interface OrdersScreenProps {
    marketId: string;
    onGoToDetail: (orderId: string) => void;
    onCloseDetail: () => void;
    resolveClient: (uid: string) => Client | null;
}

interface RequestStatus {
    completed: boolean;
    pending: boolean;
    error: string;
}

const demoSignIn = () =>
    firebase
        .auth()
        .signInWithEmailAndPassword('noreply@bookinggood.net', '123123')
        .catch((err) => {
            console.log(err);
            alert('Грешка при влизане!');
        });

const Login = ({ signInSuccessUrl = '/' }: { signInSuccessUrl?: string }) => {
    useEffect(() => {
        ui.start('#firebaseui-auth-container', {
            signInSuccessUrl,
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    customParameters: {
                        prompt: 'select_account',
                    },
                },
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
        });
        return () => ui.reset();
    }, [signInSuccessUrl]);

    return (
        <div className="login-container">
            <Segment className="login-container-inner">
                <div id="firebaseui-auth-container" />
                <div className="demo-sign-in">
                    <Button
                        onClick={demoSignIn}
                        basic
                        color="blue"
                        content="Демо достъп"
                    />
                </div>
            </Segment>
        </div>
    );
};

const range2str = (range: PickupRange) => {
    const [date, time] = range.begin.split(' ');
    const [date2, time2] = range.end.split(' ');

    const datePretty = dayjs(date).calendar(dayjs(), calendarLocale);
    return date === date2
        ? `${datePretty} / ${time} - ${time2}`
        : `${date} / ${time} - ${date2} ${time2}`;
};
const timestamp2str = (timestamp: string) => dayjs(timestamp).format('L LTS');
// TODO
const status2str = (status: string) => status;

const useLoadMarketOrders = (
    marketId: string,
    statuses: string[],
    orderBy: string
): OrderDetails[] | null => {
    const [orders, setOrders] = useState<OrderDetails[] | null>(null);
    // it's to avoid the array being memoized
    // otherwise triggers non-stop reapplying the effect
    const statusesStr = JSON.stringify(statuses);

    useEffect(() => {
        const unsub = db
            .collection('orders')
            .where('market_id', '==', marketId)
            .where('status', 'in', JSON.parse(statusesStr))
            .orderBy(orderBy)
            .onSnapshot((snap) => {
                const list: OrderDetails[] = [];
                snap.forEach((doc) => {
                    list.push({
                        id: doc.id,
                        ...doc.data(),
                    } as OrderDetails);
                });
                console.log('orders', list);

                setOrders(list);
            });
        return unsub;
    }, [marketId, statusesStr, orderBy]);
    return orders;
};

const useClientsInfo = (market_id: string | undefined | null) => {
    const [state, setState] = useState<ClientsMap>({});
    useEffect(() => {
        if (!market_id) return;

        const unsub = db
            .collection('market-private')
            .doc(market_id)
            .collection('clients')
            .onSnapshot((snap) => {
                console.log('snap', snap, snap.docs);
                const obj: ClientsMap = {};
                snap.forEach((doc) => {
                    obj[doc.id] = {
                        uid: doc.id,
                        ...doc.data(),
                    } as Client;
                });
                console.log('Clients', market_id, obj);
                setState(obj);
            });

        return unsub;
    }, [market_id]);
    return state;
};

const AllOrders = ({
    marketId,
    resolveClient,
    onGoToDetail,
    onCloseDetail,
}: OrdersScreenProps) => {
    const [orders, setOrders] = useState<OrderDetails[] | null>(null);
    const { orderId } = useParams();

    useEffect(() => {
        const unsub = db
            .collection('orders')
            .where('market_id', '==', marketId)
            .orderBy('created', 'desc')
            .onSnapshot((snap) => {
                const list: OrderDetails[] = [];
                snap.forEach((doc) => {
                    list.push({
                        id: doc.id,
                        ...doc.data(),
                    } as OrderDetails);
                });
                console.log('orders', list);

                setOrders(list);
            });
        return unsub;
    }, [marketId]);

    return (
        <div className="orders-list">
            {!orders && <Loader active />}
            {!!orderId && (
                <OrderViewPopup
                    id={orderId}
                    onClose={onCloseDetail}
                    resolveClient={resolveClient}
                />
            )}
            {!!orders && (
                <Table basic="very" selectable>
                    <Table.Header>
                        <Table.Row>
                            {[
                                'Клиент',
                                'Заявка',
                                'Желано време за изпълнение',
                                'Статус',
                                'Създадена',
                                'Последна промяна',
                            ].map((h) => (
                                <Table.HeaderCell key={h}>{h}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orders.map((order) => {
                            const {
                                user_uid,
                                details,
                                created,
                                updated,
                                pickup_range,
                                status,
                            } = order;
                            return (
                                <Table.Row
                                    // className={
                                    //     order.status ===
                                    //     STATUS.CANCEL_AFTER_ACCEPTED
                                    //         ? 'error'
                                    //         : undefined
                                    // }
                                    key={order.id}
                                    onClick={() => onGoToDetail(order.id)}
                                >
                                    <Table.Cell>
                                        {resolveClient(user_uid)?.name || 'n/a'}
                                    </Table.Cell>
                                    <Table.Cell>{details}</Table.Cell>
                                    <Table.Cell>
                                        {range2str(pickup_range)}
                                    </Table.Cell>
                                    <Table.Cell>{status}</Table.Cell>
                                    <Table.Cell>
                                        {timestamp2str(created)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {timestamp2str(updated)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
};

const NewOrders = ({
    marketId,
    resolveClient,
    onGoToDetail,
    onCloseDetail,
}: OrdersScreenProps) => {
    const { orderId } = useParams();

    const orders = useLoadMarketOrders(
        marketId,
        [STATUS.NEW],
        'pickup_range.begin'
    );

    const timeNow = dayjs();
    let lastPeriod = '';
    return (
        <div className="orders-list">
            {!orders && <Loader active />}
            {!!orderId && (
                <OrderViewPopup
                    id={orderId}
                    onClose={onCloseDetail}
                    resolveClient={resolveClient}
                />
            )}
            {!!orders && (
                <Table basic="very" selectable>
                    <Table.Header>
                        <Table.Row>
                            {[
                                'Желано време за изпълнение',
                                'Заявка',
                                'Статус',
                                'Клиент',
                                'Последна промяна',
                            ].map((h) => (
                                <Table.HeaderCell key={h}>{h}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orders.map((order) => {
                            const {
                                user_uid,
                                details,
                                updated,
                                pickup_range,
                                status,
                            } = order;

                            let label = '';
                            if (lastPeriod !== pickup_range.begin) {
                                label = range2str(pickup_range);
                                lastPeriod = pickup_range.begin;
                            }

                            return (
                                <React.Fragment key={order.id}>
                                    {!!label && (
                                        <Table.Row>
                                            <Table.Cell className="group-label">
                                                {label}
                                            </Table.Cell>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                        </Table.Row>
                                    )}
                                    <Table.Row
                                        key={order.id}
                                        className={cx({
                                            warning: dayjs(
                                                pickup_range.begin
                                            ).isBefore(timeNow),
                                        })}
                                        onClick={() => onGoToDetail(order.id)}
                                    >
                                        <Table.Cell>
                                            {range2str(pickup_range)}
                                        </Table.Cell>
                                        <Table.Cell>{details}</Table.Cell>
                                        <Table.Cell>{status}</Table.Cell>
                                        <Table.Cell>
                                            {resolveClient(user_uid)?.name ||
                                                'n/a'}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {timestamp2str(updated)}
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
};

const NegotiatingOrders = ({
    marketId,
    resolveClient,
    onGoToDetail,
    onCloseDetail,
}: OrdersScreenProps) => {
    const { orderId } = useParams();

    const orders = useLoadMarketOrders(
        marketId,
        [STATUS.CLIENT_RESPONSE, STATUS.WAITING_CLIENT_RESPONSE],
        'pickup_range.begin'
    );

    const timeNow = dayjs();
    let lastPeriod = '';
    return (
        <div className="orders-list">
            {!orders && <Loader active />}
            {!!orderId && (
                <OrderViewPopup
                    id={orderId}
                    onClose={onCloseDetail}
                    resolveClient={resolveClient}
                />
            )}
            {!!orders && (
                <Table basic="very" selectable>
                    <Table.Header>
                        <Table.Row>
                            {[
                                'Желано време за изпълнение',
                                'Заявка',
                                'Статус',
                                'Клиент',
                                'Последна промяна',
                            ].map((h) => (
                                <Table.HeaderCell key={h}>{h}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orders.map((order) => {
                            const {
                                user_uid,
                                details,
                                updated,
                                pickup_range,
                                status,
                            } = order;

                            let label = '';
                            if (lastPeriod !== pickup_range.begin) {
                                label = range2str(pickup_range);
                                lastPeriod = pickup_range.begin;
                            }

                            return (
                                <React.Fragment key={order.id}>
                                    {!!label && (
                                        <Table.Row>
                                            <Table.Cell className="group-label">
                                                {label}
                                            </Table.Cell>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                        </Table.Row>
                                    )}
                                    <Table.Row
                                        key={order.id}
                                        className={cx({
                                            error: dayjs(
                                                pickup_range.begin
                                            ).isBefore(timeNow),
                                            warning:
                                                status ===
                                                STATUS.CLIENT_RESPONSE,
                                        })}
                                        onClick={() => onGoToDetail(order.id)}
                                    >
                                        <Table.Cell>
                                            {range2str(pickup_range)}
                                        </Table.Cell>
                                        <Table.Cell>{details}</Table.Cell>
                                        <Table.Cell>{status}</Table.Cell>
                                        <Table.Cell>
                                            {resolveClient(user_uid)?.name ||
                                                'n/a'}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {timestamp2str(updated)}
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
};

const AcceptedOrders = ({
    marketId,
    resolveClient,
    onGoToDetail,
    onCloseDetail,
}: OrdersScreenProps) => {
    const { orderId } = useParams();

    const orders = useLoadMarketOrders(
        marketId,
        [STATUS.ACCEPTED], // STATUS.CANCEL_AFTER_ACCEPTED],
        'pickup_range.begin'
    );

    const timeNow = dayjs();
    let lastPeriod = '';

    return (
        <div className="orders-list">
            {!orders && <Loader active />}
            {!!orderId && (
                <OrderViewPopup
                    id={orderId}
                    onClose={onCloseDetail}
                    resolveClient={resolveClient}
                />
            )}
            {!!orders && (
                <Table basic="very" selectable>
                    <Table.Header>
                        <Table.Row>
                            {[
                                'Желано време за изпълнение',
                                'Заявка',
                                'Статус',
                                'Клиент',
                                'Последна промяна',
                            ].map((h) => (
                                <Table.HeaderCell key={h}>{h}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orders.map((order) => {
                            const {
                                user_uid,
                                details,
                                pickup_range,
                                updated,
                                status,
                            } = order;
                            let label = '';
                            if (lastPeriod !== pickup_range.begin) {
                                label = range2str(pickup_range);
                                lastPeriod = pickup_range.begin;
                            }

                            return (
                                <React.Fragment key={order.id}>
                                    {!!label && (
                                        <Table.Row>
                                            <Table.Cell className="group-label">
                                                {label}
                                            </Table.Cell>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                        </Table.Row>
                                    )}

                                    <Table.Row
                                        className={cx({
                                            warning: dayjs(
                                                pickup_range.begin
                                            ).isBefore(timeNow),
                                            // error:
                                            //     order.status ===
                                            //     STATUS.CANCEL_AFTER_ACCEPTED
                                        })}
                                        key={order.id}
                                        onClick={() => onGoToDetail(order.id)}
                                    >
                                        <Table.Cell>
                                            {range2str(pickup_range)}
                                        </Table.Cell>
                                        <Table.Cell>{details}</Table.Cell>
                                        <Table.Cell>{status}</Table.Cell>
                                        <Table.Cell>
                                            {resolveClient(user_uid)?.name ||
                                                'n/a'}
                                        </Table.Cell>
                                        <Table.Cell>{updated}</Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
};
const ReadyOrders = ({
    marketId,
    resolveClient,
    onGoToDetail,
    onCloseDetail,
}: OrdersScreenProps) => {
    const { orderId } = useParams();

    const orders = useLoadMarketOrders(
        marketId,
        [STATUS.READY],
        'pickup_range.begin'
    );

    const timeNow = dayjs();
    let lastPeriod = '';

    return (
        <div className="orders-list">
            {!orders && <Loader active />}
            {!!orderId && (
                <OrderViewPopup
                    id={orderId}
                    onClose={onCloseDetail}
                    resolveClient={resolveClient}
                />
            )}
            {!!orders && (
                <Table basic="very" selectable>
                    <Table.Header>
                        <Table.Row>
                            {[
                                'Желано време за изпълнение',
                                'Заявка',
                                'Статус',
                                'Клиент',
                                'Последна промяна',
                            ].map((h) => (
                                <Table.HeaderCell key={h}>{h}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orders.map((order) => {
                            const {
                                user_uid,
                                details,
                                pickup_range,
                                updated,
                                status,
                            } = order;
                            let label = '';
                            if (lastPeriod !== pickup_range.begin) {
                                label = range2str(pickup_range);
                                lastPeriod = pickup_range.begin;
                            }

                            return (
                                <React.Fragment key={order.id}>
                                    {!!label && (
                                        <Table.Row>
                                            <Table.Cell className="group-label">
                                                {label}
                                            </Table.Cell>
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                            <Table.Cell />
                                        </Table.Row>
                                    )}

                                    <Table.Row
                                        className={cx({
                                            warning: dayjs(
                                                pickup_range.end
                                            ).isBefore(timeNow),
                                        })}
                                        key={order.id}
                                        onClick={() => onGoToDetail(order.id)}
                                    >
                                        <Table.Cell>
                                            {range2str(pickup_range)}
                                        </Table.Cell>
                                        <Table.Cell>{details}</Table.Cell>
                                        <Table.Cell>{status}</Table.Cell>
                                        <Table.Cell>
                                            {resolveClient(user_uid)?.name ||
                                                'n/a'}
                                        </Table.Cell>
                                        <Table.Cell>{updated}</Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
};

const OrderNotFound = () => (
    <div className="market-not-found">
        За съжаление тази страница не е намерена. Свържете се с разработчика.
    </div>
);

const reIsAdminChange = /^ADMIN_/;

const History: React.FC<{ items: { [ts: string]: any } }> = ({
    items: history,
}) => {
    return (
        <div>
            <h4>История</h4>
            <List relaxed="very" divided>
                {Object.keys(history).map((timestamp) => {
                    const { type, data, message } = history[timestamp];
                    const { pickup_range, details, status } = data;
                    return (
                        <List.Item key={timestamp}>
                            <List.Content>
                                <List.Header>
                                    {reIsAdminChange.test(type)
                                        ? 'Ние'
                                        : 'Клиент'}
                                </List.Header>
                                <List.Description>
                                    {timestamp2str(timestamp)}
                                    {!!status && (
                                        <div>Статус: {status2str(status)}</div>
                                    )}
                                    {!!pickup_range && (
                                        <div>
                                            Време за изпълнение:{' '}
                                            {range2str(pickup_range)}
                                        </div>
                                    )}
                                    {!!details && <div>Заявка: {details}</div>}
                                    {!!message && (
                                        <div className="order-history-message">
                                            {message}
                                        </div>
                                    )}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        </div>
    );
};

interface OrderPopupProps {
    id: string;
    onClose: () => void;
    resolveClient: (uid: string) => Client | null;
}
const OrderViewPopup: React.FC<OrderPopupProps> = ({
    onClose,
    id,
    resolveClient,
}) => {
    const [loaded, setLoaded] = useState<OrderDetails | null>(null);
    const [changed, setChanged] = useState<OrderDetails | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [notFound, setNotFound] = useState(false);

    const client = resolveClient(loaded?.user_uid || '') || {
        name: 'n/a',
        phone_number: 'n/a',
    };

    const isChanged = !!(changed || message);

    useEffect(() => {
        const unsub = db
            .collection('orders')
            .doc(id)
            .onSnapshot((doc) => {
                console.log('Order info loaded', doc);
                if (!doc.exists) {
                    setNotFound(true);
                    return;
                }

                setLoaded({
                    id: doc.id,
                    ...doc.data(),
                } as OrderDetails);
                setChanged(null);
            });

        return unsub;
    }, [id]);

    const { matchingRange, pickupRangeOptions, range2string } = usePickupRange(
        0,
        loaded?.pickup_range
    );

    useEffect(() => {
        if (
            message &&
            changed?.status !== STATUS.WAITING_CLIENT_RESPONSE &&
            loaded?.status !== STATUS.WAITING_CLIENT_RESPONSE
        )
            setMessage(null);
    }, [message, changed, loaded]);

    const submit = () => {
        if (!isChanged) return;

        setPending(true);
        // db.collection('orders')
        //     .doc(id)
        //     .update(changed)
        functions
            .httpsCallable('adminUpdateOrder')({
                ...changed,
                id,
                market_id: loaded!.market_id,
                message,
            })
            .catch((err) => {
                console.log('Error saving:', err);
                alert('Error saving. Please contact the developers');
            })
            .finally(() => {
                setPending(false);
                setMessage(null);
            });
    };

    const [pending, setPending] = useState(false);
    const clear = () => setChanged(null);

    const onChange = (e: React.SyntheticEvent, { name, value }: any) =>
        setChanged({ ...changed, [name]: value } as any);
    const onChangeRange = (e: React.SyntheticEvent, { name, value }: any) =>
        setChanged({ ...changed, [name]: matchingRange[value] } as any);

    const content = (
        <div>
            {((!loaded && !notFound) || pending) && <Loader active />}
            {notFound && <OrderNotFound />}

            {!!loaded && (
                <div className="order-view">
                    <h2>
                        {client.name}
                        &nbsp;&nbsp;&nbsp;
                        <a href={`tel:${client.phone_number}`}>
                            {client.phone_number}
                        </a>
                    </h2>
                    <div>
                        Последна промяна на {timestamp2str(loaded.updated)}
                    </div>
                    <br />
                    <Form>
                        <Form.Group>
                            <Form.Dropdown
                                label="Статус"
                                selection
                                floating
                                name="status"
                                selectOnBlur={false}
                                selectOnNavigation={false}
                                disabled={pending}
                                options={statusDropdownOptions}
                                value={changed?.status || loaded.status}
                                onChange={onChange}
                            />
                            <Form.Dropdown
                                options={pickupRangeOptions}
                                selection
                                required
                                disabled={pending}
                                name="pickup_range"
                                value={range2string(
                                    changed?.pickup_range || loaded.pickup_range
                                )}
                                selectOnBlur={false}
                                selectOnNavigation={false}
                                onChange={onChangeRange}
                                label="Предпочитано време за взимане на покупките"
                            />
                        </Form.Group>

                        <Form.TextArea
                            label="Заявка"
                            name="details"
                            disabled={pending}
                            value={changed?.details || loaded.details}
                            onChange={onChange}
                        />
                        {!!loaded.history && <History items={loaded.history} />}
                    </Form>
                </div>
            )}
        </div>
    );

    return (
        <Modal open closeIcon onClose={onClose} closeOnDimmerClick>
            <Modal.Header>Поръчка {id}</Modal.Header>
            <Modal.Content scrolling className="order-popup-content">
                {content}
            </Modal.Content>
            <Modal.Actions>
                {(changed?.status === STATUS.WAITING_CLIENT_RESPONSE ||
                    loaded?.status === STATUS.WAITING_CLIENT_RESPONSE) && (
                    <Form.TextArea
                        className="order-popup-message"
                        placeholder="Съобщение към клиента"
                        disabled={pending}
                        value={message || ''}
                        onChange={(e, { value }) =>
                            setMessage((value as string) || null)
                        }
                    />
                )}
                {!!changed && (
                    <Button onClick={clear} disabled={pending}>
                        Изчисти промените
                    </Button>
                )}
                <Button
                    primary
                    onClick={submit}
                    disabled={pending || !isChanged}
                    loading={pending}
                >
                    Запази
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const Navbar: React.FC<{ showMenus: boolean; showLivePreview: boolean }> = ({
    showMenus,
    showLivePreview,
}) => {
    const user = useAuth();
    const loc = useLocation();
    const isMobile = useMediaQuery('(max-width: 1024px)');
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        const el = window.document.getElementsByClassName('sidebar menu')?.[0];
        if (el) {
            const onClick = (e: any) => {
                setShowSidebar(false);
            };
            el.addEventListener('click', onClick);
            return () => el.removeEventListener('click', onClick);
        }
    }, []);

    const [title, setTitle] = useState('');

    const toa = (name: string, to: string) => {
        const active = loc.pathname.indexOf(to) === 0;
        if (active && title !== name) {
            console.log('Set page title', name);
            setTitle(name);
        }
        return {
            name,
            to,
            active,
        };
    };
    console.log('route loc', loc);

    const signout = () => firebase.auth().signOut();

    const leftItems = showMenus ? (
        <>
            <Menu.Item as={Link} {...toa('Нови', '/new')} />
            <Menu.Item as={Link} {...toa('Уточняване', '/negotiating')} />
            <Menu.Item as={Link} {...toa('Приети', '/accepted')} />
            <Menu.Item as={Link} {...toa('Готови', '/ready')} />
            <Menu.Item as={Link} {...toa('Всички заявки', '/all')} />
        </>
    ) : null;

    const rightItems = [];
    if (showLivePreview)
        rightItems.push(
            <Menu.Item key="preview">
                <a href="https://store.bookinggood.bg" target="blank">
                    Live preview
                </a>
            </Menu.Item>
        );
    if (user) {
        rightItems.push(
            <Menu.Item
                key="admin"
                as={Link}
                {...toa('Администрация', '/administration')}
            />
        );

        if (isMobile) {
            rightItems.push(
                <Menu.Item key="profile">
                    <h3>Профил</h3>
                    {user.email}
                    <Menu.Item onClick={signout}>Излез</Menu.Item>
                </Menu.Item>
            );
        } else {
            rightItems.push(
                <Dropdown item icon="user outline" key="profile">
                    <Dropdown.Menu>
                        <Dropdown.Item disabled>
                            Signed in as {user.email}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={signout}>
                            <Icon name="log out" />
                            Log out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
    } else if (user === null) {
        rightItems.push(
            <Menu.Item key="sign-in" name="Sign in" as={Link} to="/login" />
        );
    }

    const sidebar = (
        <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            onHide={() => setShowSidebar(false)}
            vertical
            visible={showSidebar}
        >
            <Menu.Item>
                <h3>Заявки</h3>
                {leftItems}
            </Menu.Item>
            <Menu.Item>
                <h3>Управление</h3>
                {rightItems}
            </Menu.Item>
        </Sidebar>
    );
    return (
        <>
            <Menu secondary fixed="top" id="navbar" inverted size="massive">
                {!isMobile ? (
                    <>
                        {leftItems}
                        <Menu.Menu position="right">{rightItems}</Menu.Menu>
                    </>
                ) : (
                    <>
                        <Menu.Item
                            name="menu"
                            onClick={() => setShowSidebar(!showSidebar)}
                        >
                            <Icon name="bars" />
                        </Menu.Item>
                        <Menu.Item header>{title}</Menu.Item>
                    </>
                )}
            </Menu>
            <div style={{ width: '100%', height: 65 }} />
            {sidebar}
        </>
    );
};

const useMarketInfo = () => {
    const user = useAuth();
    const [market_id, setMarketId] = useState<string | null | undefined>();
    const [marketInfo, setMarketInfo] = useState<Market | null>(null);

    useEffect(() => {
        if (!user) return;
        return (
            db
                .collection('market-private')
                // .where('accounts', 'array-contains', user.uid)
                .where('accounts.' + user.uid, 'in', ['admin', 'staff'])
                .onSnapshot(
                    (snap) => {
                        console.log(
                            'market-private snap',
                            snap.empty,
                            snap.docs
                        );
                        const doc = snap.docs?.[0];
                        if (doc) {
                            console.log('doc', doc.id, doc.data());
                            setMarketId(doc.id);
                        } else {
                            setMarketId(null);
                        }
                    },
                    (err) => {
                        console.log('Error fetching market id', err);
                    }
                )
        );
    }, [user]);

    useEffect(() => {
        if (!user || !market_id) return;
        return db
            .collection('markets')
            .doc(market_id)
            .onSnapshot(
                (doc) => {
                    if (doc.exists) {
                        console.log('Market info', doc.id, doc.data());
                        setMarketInfo({ ...doc.data(), id: doc.id } as Market);
                    } else {
                        setMarketInfo(null);
                    }
                },
                (err) => {
                    console.log('Error fetching market id', err);
                }
            );
    }, [user, market_id]);

    return { market_id, user, marketInfo };
};

const UserNotAdmin = () => {
    return (
        <div className="no-admin-access">
            <div>
                <div>
                    В момента нямате административен достъп до нито един
                    магазин.
                </div>
                <br />
                <div>
                    <a href={'mailto:info@bookinggood.net'}>Пишете ни</a> за
                    повече информация.
                </div>
            </div>
        </div>
    );
};

const Administration: React.FC<{ marketInfo: Market }> = ({ marketInfo }) => {
    const [state, setState] = useState(marketInfo);
    const [status, setStatus] = useState<RequestStatus>({
        completed: false,
        pending: false,
        error: '',
    });

    const isChanged = state !== marketInfo;

    const onChange = (e: any, { name, value }: any) =>
        setState({ ...state, [name]: value });

    const v = (name: keyof Market) => ({
        value: state[name] as any,
        required: true,
        disabled: status.pending,
        name,
        onChange,
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (!isChanged) return;

        setStatus({ ...status, pending: true, error: '' });
        db.collection('markets')
            .doc(marketInfo.id)
            .update(state)
            .then(() =>
                setStatus({ ...status, completed: true, pending: false })
            )
            .catch((err) =>
                setStatus({ ...status, error: err.toString(), pending: false })
            );
    };
    const onClear = () => {
        setState(marketInfo);
    };

    return (
        <Container>
            <Form className="admin-market-form" onSubmit={onSubmit}>
                <div
                    style={{
                        margin: '1em 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    <Image
                        src={state.logoURL}
                        size="tiny"
                        verticalAlign="middle"
                    />
                    <div>
                        <Checkbox
                            toggle
                            checked={!state.hidden}
                            name="hidden"
                            onChange={(e, { name, checked }) =>
                                onChange(e, { name, value: !checked })
                            }
                            label="Видим на сайта"
                        />
                        <br />
                        <Checkbox
                            toggle
                            checked={state.active}
                            name="active"
                            onChange={(e, { name, checked }) =>
                                onChange(e, { name, value: checked })
                            }
                            label="Онлайн поръчки"
                        />
                    </div>
                </div>
                <Form.Input fluid label="Име" {...v('name')} />
                <Form.Group>
                    <Form.Input width={3} label="Държава" {...v('country')} />
                    <Form.Input width={3} label="Град" {...v('city')} />
                    <Form.Input width={10} label="Адрес" {...v('address')} />
                </Form.Group>
                <Form.TextArea label="Описание" {...v('description')} />
                <Form.Group>
                    <Form.Button
                        disabled={!isChanged}
                        loading={status.pending}
                        primary
                    >
                        Запази
                    </Form.Button>
                    <Form.Button
                        disabled={!isChanged}
                        loading={status.pending}
                        type="button"
                        onClick={onClear}
                    >
                        Изчисти промени
                    </Form.Button>
                </Form.Group>
            </Form>
            {status.completed && <Message success>Готово</Message>}
            {!!status.error && <Message negative>{status.error}</Message>}
        </Container>
    );
};

const MegaAdminScreen: React.FC<{ user: firebase.User | null | undefined }> = ({
    user,
}) => {
    const re = /claim=([a-zA-Z0-9]+)/;
    const [, claim_id] = re.exec(window.location.search) || [];

    const [pending, setPending] = useState(false);

    const submit = () => {
        setPending(true);
        functions
            .httpsCallable('approveClaim')({
                claim_id,
            })
            .catch((err) => {
                console.log('Error saving:', err);
                alert('Error saving: ' + err.toString());
            })
            .finally(() => {
                setPending(false);
            });
    };

    return (
        <div style={{ padding: 10, lineHeight: '2em', fontSize: '2em' }}>
            <h1>Mega admin</h1>
            Current user: {user?.email}
            <br />
            Claim ID: {claim_id}
            <br />
            <Button loading={pending} primary onClick={submit}>
                Submit
            </Button>
        </div>
    );
};

const isMegaAdminRoute = window.location.pathname.indexOf('/megaadmin') === 0;
console.log('PATH', window.location.pathname, isMegaAdminRoute);

function App() {
    const { market_id, user, marketInfo } = useMarketInfo();
    const clients = useClientsInfo(market_id);

    const router = useHistory();
    const goTo = (base: string) => () => router.push(base);
    const goToDetail = (base: string) => (id: string) =>
        router.push(`${base}/${id}`);

    const data = useMemo(
        () => ({
            marketInfo,
            clients,
            resolveClient: (uid: string) => clients[uid] || null,
        }),
        [marketInfo, clients]
    );

    if (isMegaAdminRoute) return <MegaAdminScreen user={user} />;

    return (
        <DataContext.Provider value={data}>
            <Navbar
                showMenus={!!market_id}
                showLivePreview={marketInfo?.active === false}
            />
            <Sidebar.Pushable>
                <Sidebar.Pusher>
                    <div className="app">
                        {market_id === null && !!user && <UserNotAdmin />}
                        {user === undefined && <Loader active />}
                        {user === null && (
                            <Login signInSuccessUrl={window.location.href} />
                        )}
                        <Switch>
                            {!!market_id && !!user ? (
                                <>
                                    <Route path={['/new/:orderId', '/new']}>
                                        <NewOrders
                                            marketId={market_id}
                                            onGoToDetail={goToDetail('/new')}
                                            onCloseDetail={goTo('/new')}
                                            resolveClient={data.resolveClient}
                                        />
                                    </Route>
                                    <Route
                                        path={[
                                            '/negotiating/:orderId',
                                            '/negotiating',
                                        ]}
                                    >
                                        <NegotiatingOrders
                                            marketId={market_id}
                                            onGoToDetail={goToDetail(
                                                '/negotiating'
                                            )}
                                            onCloseDetail={goTo('/negotiating')}
                                            resolveClient={data.resolveClient}
                                        />
                                    </Route>
                                    <Route
                                        path={[
                                            '/accepted/:orderId',
                                            '/accepted',
                                        ]}
                                    >
                                        <AcceptedOrders
                                            marketId={market_id}
                                            onGoToDetail={goToDetail(
                                                '/accepted'
                                            )}
                                            onCloseDetail={goTo('/accepted')}
                                            resolveClient={data.resolveClient}
                                        />
                                    </Route>
                                    <Route path={['/ready/:orderId', '/ready']}>
                                        <ReadyOrders
                                            marketId={market_id}
                                            onGoToDetail={goToDetail('/ready')}
                                            onCloseDetail={goTo('/ready')}
                                            resolveClient={data.resolveClient}
                                        />
                                    </Route>
                                    <Route path={['/all/:orderId', '/all']}>
                                        <AllOrders
                                            marketId={market_id}
                                            onGoToDetail={goToDetail('/all')}
                                            onCloseDetail={goTo('/all')}
                                            resolveClient={data.resolveClient}
                                        />
                                    </Route>
                                    <Route path="/administration">
                                        {!!marketInfo && (
                                            <Administration
                                                marketInfo={marketInfo}
                                            />
                                        )}
                                    </Route>
                                    <Route path="/terms">
                                        <Terms />
                                    </Route>
                                    <Route exact path="/">
                                        <Redirect to="new" />
                                    </Route>
                                </>
                            ) : (
                                <>
                                    <Route path="/terms">
                                        <Terms />
                                    </Route>
                                </>
                            )}
                        </Switch>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </DataContext.Provider>
    );
}

ReactDOM.render(<Footer />, document.getElementById('footer'));

export default () => (
    <Router>
        <App />
    </Router>
);
